import React from "react";
// import { useNavigate } from "react-router";
import "./customerAdvert.css";
// import { CUSTOMERS, EDIT, ADVERT } from "../../../constants/constants.routes";

const CustomerAdvert = (props) => {
  // let navigate = useNavigate();

  return (
    <React.Fragment>
      <div>details of customers advert</div>
    </React.Fragment>
  );
};

export default CustomerAdvert;
