import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CustomersList from './CustomersList/CustomersList';
import { useNavigate } from 'react-router';
import {
  CUSTOMERS,
  NEW,
  EDIT,
  AUTH_SIGNOUT,
  FORCED,
} from './../../constants/constants.routes';
import { useHttp } from '../../hooks/http.hook';
import { getHostResponseObject } from '../../stateObjects/hostResponse.model';
import { CUSTOMERS_ENDPOINT } from '../../constants/constants.api';
import {
  ERROR_MESSAGE,
  FORCE_SIGN_OUT,
} from '../../constants/constants.hostResponseObject';
import {
  setSpinnerStatusOn,
  setSpinnerStatusOff,
} from '../../stores/SpinnerStore/SpinnerAction';
import {
  getErrorMessage,
  setErrorMessageForShow,
} from './../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import { setNoticeMessageForShow } from '../../stores/NoticeMessageStore/NoticeMessageStoreHelper';

const Customers = (props) => {
  let navigate = useNavigate();

  const hostResponseObject = getHostResponseObject();
  const [customersList, setCustomersList] = useState(hostResponseObject);
  const [loading, request] = useHttp();

  const addNewCustomer = () => {
    navigate(CUSTOMERS + EDIT + '/' + NEW, { replace: true });
  };

  useEffect(() => {
    getCustomersListRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      props.setSpinnerOn();
      console.info('spinner On');
    } else {
      props.setSpinnerOff();
      console.info('spinner Off');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getCustomersListRequest = async () => {
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + CUSTOMERS_ENDPOINT,
        'POST'
      );

      console.info('result:', result);

      if (result[ERROR_MESSAGE]) {
        props.setErrorMessage(result[ERROR_MESSAGE]);
        // props.setSpinnerOff();

        if (result[FORCE_SIGN_OUT]) {
          console.info('forced sign out');
          throw result[ERROR_MESSAGE];
        }
      }

      setCustomersList({ ...hostResponseObject, ...result });
    } catch (e) {
      // props.setSpinnerOff();
      props.setNoticeMessage(e);
      navigate(`${AUTH_SIGNOUT}/${FORCED}`, { replace: true });
    }
  };

  return (
    <React.Fragment>
      <div className='container-fluid text-center mt-5 w-25'>
        <div className='section-title'>Customers</div>
        <div className='section-title buttonToRight'>
          <button onClick={addNewCustomer}>Add new</button>
        </div>
        <CustomersList customersList={customersList} />
      </div>
      <div className='p-3'>
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className='alert alert-danger' role='alert'>
              {props.errorMessage}
            </div>
          ) : (
            ''
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className='alert alert-success' role='alert'>
              {props.successMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpinnerOn: () => {
      dispatch(setSpinnerStatusOn());
    },
    setSpinnerOff: () => {
      dispatch(setSpinnerStatusOff());
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setNoticeMessage: (noticeMessage) => {
      setNoticeMessageForShow(dispatch, noticeMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getErrorMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
