import React from "react";
import "./customersList.css";
import Customer from "../Customer/Customer";
import { DATA } from "../../../constants/constants.hostResponseObject";

const CustomersList = (props) => {
  return (
    <React.Fragment>
      {props.customersList[DATA].map((customerElement) => {
        return <Customer customerElement={customerElement} key={customerElement.id} />;
      })}
    </React.Fragment>
  );
};

export default CustomersList;
