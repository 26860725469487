import {
  AUTH_SET_CURRENT_USER,
  AUTH_SET_AUTOLOGIN_TRY_USED,
  AUTH_SET_CURRENT_USER_PERMISSIONS,
} from "./constantsAuthStore";

export function setCurrentUserStore(currentUser) {
  console.info("currentUser:", currentUser);
  return {
    type: AUTH_SET_CURRENT_USER,
    payload: currentUser,
  };
}

export function setAutoLoginTryUsedStore(autoLoginTryUsed) {
  return {
    type: AUTH_SET_AUTOLOGIN_TRY_USED,
    payload: autoLoginTryUsed,
  };
}

export function setCurrentUserPermissionsStore(currentUserPermissions) {
  console.info("setCurrentUserPermissions:", currentUserPermissions);
  return {
    type: AUTH_SET_CURRENT_USER_PERMISSIONS,
    payload: currentUserPermissions,
  };
}
