export const SERVICE_MANAGER = 'serviceManager';
export const ACCOUNT_MANAGER = 'accountManager';
export const SUPERVISOR = 'supervisor';
export const STATUS = 'status';
export const USER_EMAIL = 'userEmail';
export const GRANTED = 'granted';
export const STARTTIME = 'startTime';
export const ENDTIME = 'endTime';
export const PERMISSIONS = 'permissions';
export const UID = 'uid';
export const WEEK_DAYS = 'weekDays';
export const WEEK_END = 'weekEnd';
export const DELOS_STOP_ACTIVITY = 'delos_stop_activity';
export const AGATA_STOP_ACTIVITY = 'agata_stop_activity';
export const REHOBOAM_STOP_ACTIVITY = 'rehoboam_stop_activity';
export const REMOTE_CONFIG_UPDATED_TIME = 'remote_config_updated_time';
export const OPTIONS = 'options';
