import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getHostResponseObject } from '../../stateObjects/hostResponse.model';
import {
  MESSAGE,
  ERROR_MESSAGE,
  FORCE_SIGN_OUT,
} from '../../constants/constants.hostResponseObject';
import { useHttp } from '../../hooks/http.hook';
import {
  getErrorMessage,
  setErrorMessageForShow,
} from './../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import {
  setSpinnerStatusOn,
  setSpinnerStatusOff,
} from '../../stores/SpinnerStore/SpinnerAction';
import { AGATA_STATUS_ENDPOINT } from '../../constants/constants.api';
import { useNavigate } from 'react-router';
import { AUTH_SIGNOUT, FORCED } from '../../constants/constants.routes';
import { setNoticeMessageForShow } from '../../stores/NoticeMessageStore/NoticeMessageStoreHelper';

const CheckStatus = (props) => {
  let navigate = useNavigate();

  const hostResponseObject = getHostResponseObject();
  const [agataStatus, setAgataStatus] = useState(hostResponseObject);
  const [loading, request] = useHttp();

  let { host } = useParams();

  useEffect(() => {
    checkStatusRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.info('loading status has been changed');

    if (loading) {
      props.setSpinnerOn();
      console.info('spinner On');
    } else {
      props.setSpinnerOff();
      console.info('spinner Off');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const checkStatusRequest = async () => {
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + AGATA_STATUS_ENDPOINT,
        'POST'
      );

      console.info('data:', result);
      setAgataStatus({ ...hostResponseObject, ...result });

      if (result[ERROR_MESSAGE]) {
        props.setErrorMessage(result[ERROR_MESSAGE]);
        props.setNoticeMessage(result[ERROR_MESSAGE]);
        props.setSpinnerOff();

        if (result[FORCE_SIGN_OUT]) {
          throw result[ERROR_MESSAGE];
        }
      }
    } catch (e) {
      navigate(`${AUTH_SIGNOUT}/${FORCED}`, { replace: true });
    }
  };

  return (
    <React.Fragment>
      <div className='container-fluid text-center mt-5 w-25'>
        CheckStatus {host}: <strong>{agataStatus[MESSAGE]}</strong>
      </div>
      <div className='p-3'>
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className='alert alert-danger' role='alert'>
              {props.errorMessage}
            </div>
          ) : (
            ''
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className='alert alert-success' role='alert'>
              {props.successMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpinnerOn: () => {
      dispatch(setSpinnerStatusOn());
    },
    setSpinnerOff: () => {
      dispatch(setSpinnerStatusOff());
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setNoticeMessage: (noticeMessage) => {
      setNoticeMessageForShow(dispatch, noticeMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getErrorMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckStatus);
