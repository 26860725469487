import React from "react";
import { useNavigate } from "react-router";
import "./customer.css";
import { CUSTOMERS, EDIT, ADVERT } from "../../../constants/constants.routes";
import { EDIT_BUTTON } from "../../../constants/constants.titles";

const Customer = (props) => {
  let navigate = useNavigate();

  const customerEditAction = (event, customerElement) => {
    console.info("click on customerElement to edit:", customerElement.id);
    event.stopPropagation();
    navigate(CUSTOMERS + EDIT + "/" + customerElement.id, { replace: true });
  };

  const customerAdvertAction = (customerElement) => {
    console.info("click on customerElement to get details:", customerElement.id);
    navigate(CUSTOMERS + ADVERT + "/" + customerElement.id, { replace: true });
  };

  return (
    <React.Fragment>
      <div
        key={props.customerElement.id}
        className="formLine"
        onClick={() => customerAdvertAction(props.customerElement)}
      >
        <div>#{props.customerElement.id}</div>
        <div className="bold-text">{props.customerElement.name}</div>
        <div>Adwords ID: #{props.customerElement.adWordsID}</div>
        <div>
          <button
            className="buttonSection"
            onClick={(event) => {
              customerEditAction(event, props.customerElement);
            }}
          >
            {EDIT_BUTTON}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customer;
