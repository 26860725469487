import { SET_FORCE_UPDATE, SET_USER_FOR_EDIT, SET_FORM_CHECK_STATUS } from "./constantsBrainStore";

export function setForceUpdateStore(forceUpdate) {
  return {
    type: SET_FORCE_UPDATE,
    payload: forceUpdate,
  };
}

export function setUserForEditStore(user) {
  return {
    type: SET_USER_FOR_EDIT,
    payload: user,
  };
}

export function setFormCheckStatusStore(formStatus) {
  console.info("setFormCheckStatusStore:", formStatus);
  return {
    type: SET_FORM_CHECK_STATUS,
    payload: formStatus,
  };
}
