import React from 'react';
import { useNavigate } from 'react-router';
import './user.css';
import { connect } from 'react-redux';
import { getUserForUpdate } from './../../../stores/BrainStore/BrainsStoreHelper';
import { setUserForEditStore } from './../../../stores/BrainStore/BrainsAction';
import { USERS } from '../../../constants/constants.routes';
import { ENDTIME, STARTTIME, WEEK_DAYS, WEEK_END } from '../../../constants/constants.datamodel';

const minAccessLevel = 16384;

const User = (props) => {
  let navigate = useNavigate();

  const userClickAction = (userElement) => {
    console.info('minAccessLevel:', minAccessLevel);

    props.setUserForUpdate(userElement);

    navigate(USERS + '/' + userElement.userEmail, { replace: true });
  };

  return (
    <React.Fragment>
      <div
        key={props.userElement.uid}
        className='formLine'
        onClick={() => userClickAction(props.userElement)}
      >
        <div>{props.userElement.userEmail}</div>
        {props.userElement.maxUserLevelName ? (
          <div className='bold-text'>{props.userElement.maxUserLevelName}</div>
        ) : (
          <div className='red-text'>no role assigned</div>
        )}
        <div>start at {props.userElement[STARTTIME]} ends at start at {props.userElement[ENDTIME]}</div>
        {props.userElement[WEEK_DAYS] ? (
          <div>{`${WEEK_DAYS}`} allowed</div>
        ) : (
          <div>{`${WEEK_DAYS}`} NOT allowed</div>
        )}
        {props.userElement[WEEK_END] ? (
          <div>{`${WEEK_END}`} allowed</div>
        ) : (
          <div>{`${WEEK_END}`} NOT allowed</div>
        )}
        
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserForUpdate: (user) => {
      dispatch(setUserForEditStore(user));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    userForUpdate: getUserForUpdate(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
