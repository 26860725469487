import { connect } from 'react-redux';

const NoPermissionGranted = (props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      no permissions has been granted for {props.currentUser} do update this
      component after getting permissions list
    </div>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, null)(NoPermissionGranted);
