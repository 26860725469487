import {
  MESSAGE,
  ERROR_MESSAGE,
  DATA,
  FORCE_SIGN_OUT,
} from '../constants/constants.hostResponseObject';
import { STATUS } from '../constants/constants.datamodel';

export const hostResponseObject = {
  [DATA]: [],
  [MESSAGE]: '',
  [STATUS]: '',
  [ERROR_MESSAGE]: '',
  [FORCE_SIGN_OUT]: false,
};

export const getHostResponseObject = () => {
  return Object.assign({}, hostResponseObject);
};
