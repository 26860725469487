export function storeState(state) {
  console.info("currentState in store procedure:", state);
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (e) {
    console.log("Error while storing the state:", e);
  }
}

export function restoreState() {
  console.info("restoredStore:");
  
}
