import { database } from "./Firebase";
import { RemoteConfigRCDB } from "../../constants/constants.firebase.database";
import { ref, get, set } from "firebase/database";
import { getNewRemoteConfigRTDBObject } from "../Models/RemoteConfigRCDB.model";

export async function setRemoteConfigRCDB(remoteConfigRCDBObject) {
  const statusOfWritingRemoteConfigRTDBObject = await set(
    ref(database, `/${RemoteConfigRCDB}`),
    remoteConfigRCDBObject
  )
    .then((result) => {
      return "statusOfWritingRemoteConfigRTDBObject has been saved";
    })
    .catch((error) => {
      throw new Error("issue with saving statusOfWritingRemoteConfigRTDBObject:" + error);
    });

  return statusOfWritingRemoteConfigRTDBObject;
}

export async function getRemoteConfigRCDB() {
  let newRemoteConfigRTDBObject = getNewRemoteConfigRTDBObject();

  const snapshot = await get(ref(database, `/${RemoteConfigRCDB}`))
    .then((result) => {
      console.info("snapshot:", result);
      return result;
    })
    .catch(() => {
      return null;
    });

  let snapshotData = null;

  if (snapshot) {
    snapshotData = await snapshot.val();
  }

  console.info("snapshot data:", snapshotData);

  const remoteConfigRTDBObject = Object.assign(newRemoteConfigRTDBObject, snapshotData);

  return remoteConfigRTDBObject;
}
