export const getForceUpdate = (state) => {
  console.info('getForceUpdate:', state);
  return state?.brainState?.forceUpdate ? state.brainState.forceUpdate : 0;
};

export const getUserForUpdate = (state) => {
  console.info('getUserForUpdate:', state);
  return state?.brainState?.userForUpdate
    ? state.brainState.userForUpdate
    : null;
};

export const getFormCheckStatus = (state) => {
  console.info('getFormCheckStatus:', state);
  return state?.brainState?.formCheckStatus ? state.brainState.formCheckStatus : {};
};
