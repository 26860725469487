import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./components/App/App";
// import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { authUserReducer } from "./stores/AuthStore/AuthUserReducer";
import { spinnerReducer } from "./stores/SpinnerStore/SpinnerReducer";
import { brainReducer } from "./stores/BrainStore/BrainsReducer";
import { errorMessageReducer } from "./stores/ErrorMessageStore/ErrorMessageReducer";
import { successMessageReducer } from "./stores/SuccessMessageStore/SuccessMessageReducer";
import { noticeMessageReducer } from "./stores/NoticeMessageStore/NoticeMessageReducer";
import { DEVELOPMENT } from "./constants/constants";

document.title = "Minority Report";

if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) {
  document.title = document.title + ": " + DEVELOPMENT;
}

const appStore = configureStore({
  reducer: {
    userData: authUserReducer,
    spinner: spinnerReducer,
    errorMessage: errorMessageReducer,
    successMessage: successMessageReducer,
    brainState: brainReducer,
    noticeMessage: noticeMessageReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_AUTOLOGIN) {
  console.info("REACT_APP_AUTOLOGIN:", process.env.REACT_APP_AUTOLOGIN);
  if (!window.sessionStorage.getItem("AutoLogin")) {
    window.sessionStorage.setItem("AutoLogin", process.env.REACT_APP_AUTOLOGIN);
  }
}

root.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
