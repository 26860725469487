import './App.scss';
// import { BrowserRouter as Router } from 'react-router-dom';
import RoutesArray from '../RoutesArray/RoutesArray';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SPINNER_ON } from '../../stores/SpinnerStore/constantsSpinnerStore';
import {
  getCurrentUserValue,
  getAutoLoginTryUsedValue,
  getCurrentUserIDValue,
} from './../../stores/AuthStore/AuthStoreHelper';
import { getSpinnerStatus } from './../../stores/SpinnerStore/SpinnerStoreHelper';
import { setAutoLoginTryUsedStore } from './../../stores/AuthStore/AuthAction';
import { YES } from './../../constants/constants';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  setCurrentUserStore,
  setCurrentUserPermissionsStore,
} from '../../stores/AuthStore/AuthAction';
import { getUsersPermissions } from './../../service/Firebase/userPermissions.helper.firebase';
import { getNewUserPermissionsObject } from './../../service/Models/UserPermissions.model';
import { PERMISSIONS, STATUS } from '../../constants/constants.datamodel';
import {
  getErrorMessage,
  setErrorMessageForShow,
} from './../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from './../../stores/SuccessMessageStore/SuccessMessageStoreHelper';
import { getUserPermissionsLevel } from 'accesslevel';

function App(props) {
  const [useAutoLogin, setUseAutoLogin] = useState(
    process.env.REACT_APP_AUTOLOGIN === YES ? true : false
  );

  useEffect(() => {
    console.info(
      'process.env.REACT_APP_AUTOLOGIN:',
      process.env.REACT_APP_AUTOLOGIN
    );
    console.info('useAutoLogin:', useAutoLogin);

    if (window.sessionStorage.getItem('AutoLogin') === YES) {
      if (
        process.env.REACT_APP_USERNAME &&
        process.env.REACT_APP_USERNAME.length === 0
      ) {
        console.info('no username');
        return;
      }
      if (
        process.env.REACT_APP_PASSWORD &&
        process.env.REACT_APP_PASSWORD.length === 0
      ) {
        console.info('no username');
        return;
      }

      const auth = getAuth();

      const unsubscribeOnAuthStateChanged = onAuthStateChanged(
        auth,
        async (user) => {
          console.info('firebase current user (auth changed):', user);

          if (!user && window.sessionStorage.getItem('AutoLogin') === YES) {
            console.info('user && window.sessionStorage.getItem');
            await signInWithEmailAndPassword(
              auth,
              process.env.REACT_APP_USERNAME,
              process.env.REACT_APP_PASSWORD
            )
              .then(async (userCredential) => {
                console.info('userCredential:', userCredential);

                const user = userCredential.user;
                props.setCurrentUser(JSON.parse(JSON.stringify(user)));
                setUseAutoLogin(false);
                window.sessionStorage.setItem('AutoLogin', 'NO');

                props.setAutoLoginTryUsed();

                //TODO refactor it
                let userId = null;
                let userEmail = null;
                if (userCredential.user?.reloadUserInfo?.localId) {
                  userId = userCredential.user.reloadUserInfo.localId;
                } else {
                  throw new Error(
                    'No id in user property of Firebase Auth Object'
                  );
                }
                if (userCredential.user?.reloadUserInfo?.email) {
                  userEmail = userCredential.user.reloadUserInfo.email;
                } else {
                  throw new Error(
                    'No email in user property of Firebase Auth Object'
                  );
                }

                return { userEmail, userId };
              })
              .then(async (result) => {
                console.info('result:', result);

                //TODO refactor it
                const userPermisions = await getUsersPermissions(
                  result.userEmail,
                  result.userId
                );
                props.setSuccessMessage(userPermisions[STATUS]);
                const userPermissionsLevel = getUserPermissionsLevel(
                  userPermisions[PERMISSIONS]
                );
                props.setCurrentUserPermissions(userPermissionsLevel);
                if (userPermissionsLevel === 0) {
                  props.setErrorMessage('Current user has no any permissions.');
                }
                console.info('userPermisions:', userPermisions);
              })
              .catch((error) => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
                console.info('error: ', error);
                props.setErrorMessage(error.message);
              });
          } else if (
            user &&
            window.sessionStorage.getItem('AutoLogin') === YES
          ) {
            props.setCurrentUser(JSON.parse(JSON.stringify(user)));
            setUseAutoLogin(false);
            props.setAutoLoginTryUsed();
            window.sessionStorage.setItem('AutoLogin', 'NO');

            //TODO refactor it
            let userId = null;
            let userEmail = null;
            if (user?.reloadUserInfo?.localId) {
              userId = user.reloadUserInfo.localId;
            } else {
              throw new Error('No id in user property of Firebase Auth Object');
            }
            if (user?.reloadUserInfo?.email) {
              userEmail = user.reloadUserInfo.email;
            } else {
              throw new Error(
                'No email in user property of Firebase Auth Object'
              );
            }

            //TODO move to helper
            const userPermisions = await getUsersPermissions(userEmail, userId);
            props.setSuccessMessage(userPermisions[STATUS]);
            const userPermissionsLevel = getUserPermissionsLevel(
              userPermisions[PERMISSIONS]
            );
            props.setCurrentUserPermissions(userPermissionsLevel);
            if (userPermissionsLevel === 0) {
              props.setErrorMessage('Current user has no any permissions.');
            }
            console.info('userPermisions:', userPermisions);
            console.info('getNewObject:', getNewUserPermissionsObject());
          }
        }
      );
      unsubscribeOnAuthStateChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useAutoLogin]);

  // props && props.spinner && props.spinner === SPINNER_ON

  return (
    <div>
      {props.spinner === SPINNER_ON ? (
        <div className='spinnerdiv'>
          <div className='lds-roller'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='App'>
        <RoutesArray
        // permissionsGranted={permissionsGranted}
        // isAdmin={isAdmin}
        // initCompleted={initCompleted}
        // authStatus={authStatus}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAutoLoginTryUsed: () => {
      dispatch(setAutoLoginTryUsedStore(YES));
    },
    setCurrentUser: (userData) => {
      dispatch(setCurrentUserStore(userData));
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
    setCurrentUserPermissions: (userPermissionsLevel) => {
      dispatch(setCurrentUserPermissionsStore(userPermissionsLevel));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUserValue(state),
    spinner: getSpinnerStatus(state),
    autoLoginTryUsed: getAutoLoginTryUsedValue(state),
    uid: getCurrentUserIDValue(state),
    errorMessage: getErrorMessage(state),
    successMessage: getSuccessMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
