import { SET_FORCE_UPDATE, SET_USER_FOR_EDIT, SET_FORM_CHECK_STATUS } from "./constantsBrainStore";

const initialState = {
  forceUpdate: 0,
  userForUpdate: null,
  formCheckStatus: {},
};

export const brainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORCE_UPDATE:
      return { ...state, forceUpdate: action.payload };
    case SET_USER_FOR_EDIT:
      return { ...state, userForUpdate: action.payload };
    case SET_FORM_CHECK_STATUS:
      return { ...state, formCheckStatus: action.payload };
    default:
      return state;
  }
};
