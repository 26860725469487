import * as CONSTANTS from '../../constants/constants.routes';
// import * as CONSTANTS from '../../constants/constants';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import {
  getCurrentUserValue,
  getCurrentUserPermissionsLevel,
} from '../../stores/AuthStore/AuthStoreHelper';
import { useEffect } from 'react';
import { storeState } from '../../stores/store.helpers';
import {
  SERVICE_MANAGER,
  SUPERVISOR,
} from './../../constants/constants.datamodel';
import { ACCESS_LEVEL } from 'accesslevel';
import {
  setCurrentUserStore,
  setCurrentUserPermissionsStore,
  setAutoLoginTryUsedStore,
} from '../../stores/AuthStore/AuthAction';
import {
  PERMISSIONS,
  AUTH_SET_AUTOLOGIN_TRY_USED,
} from '../../stores/AuthStore/constantsAuthStore';
import { CURRENT_USER } from '../../constants/constants';
import {
  getNoticeMessage,
  setNoticeMessageForShow,
} from '../../stores/NoticeMessageStore/NoticeMessageStoreHelper';
import { getErrorMessage } from '../../stores/ErrorMessageStore/ErrorMessageStoreHelper';

const Navigation = (props) => {
  const { currentUser, userPermissionsLevel } = { props };

  useEffect(() => {
    if (props.currentUser) {
      storeState(props.currentState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, userPermissionsLevel]);

  useEffect(() => {
    console.info('--- only once:', props.currentUser);
    if (!props.currentUser) {
      const currentUserFromSessionStorage =
        window.sessionStorage.getItem(CURRENT_USER);
      console.info(
        '--- currentUserFromSessionStorage',
        currentUserFromSessionStorage
      );
      if (currentUserFromSessionStorage) {
        const currentStateFromJSON = JSON.parse(currentUserFromSessionStorage);
        props.setCurrentUser(currentStateFromJSON);
      }

      const permissionsFromSessionStorage =
        window.sessionStorage.getItem(PERMISSIONS);

      if (permissionsFromSessionStorage) {
        const permissionsFromJSON = JSON.parse(permissionsFromSessionStorage);
        props.setCurrentUserPermissions(permissionsFromJSON);
      }

      const autoLoginUsedFromSessionStorage = window.sessionStorage.getItem(
        AUTH_SET_AUTOLOGIN_TRY_USED
      );

      if (autoLoginUsedFromSessionStorage) {
        const autoLoginUsedFromJSON = JSON.parse(
          autoLoginUsedFromSessionStorage
        );
        props.setAutoLoginTryUsed(autoLoginUsedFromJSON);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div style={{ textAlign: 'center', padding: 10 }}>
        {
          /* istanbul ignore next */
          props.noticeMessage && props.noticeMessage.length >= 0 ? (
            <div className='alert alert-info' role='alert'>
              {props.noticeMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>

      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container-fluid'>
          <div className='navbar-header'>
            <NavLink
              className='navbar-brand'
              to={CONSTANTS.LANDING}
              id='startPage'
            >
              Delos
            </NavLink>
          </div>

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              {props.currentUser &&
              props.userPermissionsLevel >= ACCESS_LEVEL[SERVICE_MANAGER] ? (
                <li className='nav-item'>
                  <NavLink
                    className='nav-link'
                    activeclassname='active'
                    to={CONSTANTS.CUSTOMERS}
                  >
                    Customers
                  </NavLink>
                </li>
              ) : (
                ''
              )}

              {props.currentUser &&
              props.userPermissionsLevel >= ACCESS_LEVEL[SUPERVISOR] ? (
                <li className='nav-item'>
                  <NavLink
                    className='nav-link'
                    activeclassname='active'
                    to={CONSTANTS.USERS}
                  >
                    Users
                  </NavLink>
                </li>
              ) : (
                ''
              )}

              {props.currentUser &&
              props.userPermissionsLevel >= ACCESS_LEVEL[SUPERVISOR] ? (
                <li className='nav-item'>
                  <NavLink
                    className='nav-link'
                    activeclassname='active'
                    to={CONSTANTS.REMOTE_CONFIG}
                  >
                    Remote config
                  </NavLink>
                </li>
              ) : (
                ''
              )}

              {props.currentUser &&
              props.userPermissionsLevel >= ACCESS_LEVEL['level1'] ? (
                <li className='nav-item'>
                  <NavLink
                    className='nav-link'
                    activeclassname='active'
                    to={`/status/${CONSTANTS.AGATA}`}
                  >
                    Check status of Agata
                  </NavLink>
                </li>
              ) : (
                ''
              )}

              {props.currentUser &&
              props.userPermissionsLevel >= ACCESS_LEVEL['level1'] ? (
                <li className='nav-item'>
                  <NavLink
                    className='nav-link'
                    activeclassname='active'
                    to={`/status/${CONSTANTS.REHOBOAM}`}
                  >
                    Check status of Rehoboam
                  </NavLink>
                </li>
              ) : (
                ''
              )}
            </ul>

            <ul className='navbar-nav nav my-2 my-lg-0'>
              {!props.currentUser ? (
                <li className='nav-item nav-link'>
                  <NavLink
                    className='px-3'
                    to={CONSTANTS.AUTH_SIGNIN}
                    activeclassname='active'
                    id='signIn'
                  >
                    Sign In
                  </NavLink>
                </li>
              ) : (
                <li className='nav-item nav-link'>
                  <span className='mr-3'>
                    {props.currentUser ? props.currentUser : ''}
                  </span>
                  <NavLink
                    className='px-3'
                    to={CONSTANTS.AUTH_SIGNOUT}
                    activeclassname='active'
                    id='signOut'
                  >
                    Sign Out
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {
        /* istanbul ignore next */
        props.noticeMessage &&
        props.errorMessage &&
        props.errorMessage.length >= 0 &&
        props.errorMessage.length === 0 ? (
          <div className='alert alert-danger' role='alert'>
            {props.errorMessage}
          </div>
        ) : (
          ''
        )
      }
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (userData) => {
      dispatch(setCurrentUserStore(userData));
    },
    setCurrentUserPermissions: (userPermissionsLevel) => {
      dispatch(setCurrentUserPermissionsStore(userPermissionsLevel));
    },
    setAutoLoginTryUsed: (autoLoginTryUsed) => {
      dispatch(setAutoLoginTryUsedStore(autoLoginTryUsed));
    },
    setNoticeMessage: (errorMessage) => {
      setNoticeMessageForShow(dispatch, errorMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUserValue(state),
    currentState: state,
    userPermissionsLevel: getCurrentUserPermissionsLevel(state),
    noticeMessage: getNoticeMessage(state),
    errorMessage: getErrorMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
