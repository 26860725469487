import { setSuccessMessage, clearSuccessMessage } from "./SuccessMessageAction";

export const getSuccessMessage = (state) => {
  console.info("state.successMessage:", state.successMessage);
  return state.successMessage ? state.successMessage.successMessage : "";
};

export const setSuccessMessageForShow = (dispatch, successMessage) => {
  dispatch(setSuccessMessage(successMessage));
  setTimeout(() => {
    dispatch(clearSuccessMessage());
  }, 5000);
};
