import {
  // FIELDNAME,
  MINLENGTH,
  MAXLENGTH,
  REMOVELEADINGZEROS,
  VALUE,
  USERFIELDNAME,
  MINVALUE,
  MAXVALUE,
} from '../../constants/constants.validationResult';
import { getNewValidationResultObjectObject } from '../Models/ValidatorResult.model';

export const numberValidator = (value, options) => {
  const validationResultObjectObject = getNewValidationResultObjectObject();
  validationResultObjectObject[VALUE] = '';

  if (value && Number(value)) {
    validationResultObjectObject[VALUE] = Number(value);
  } else {
    validationResultObjectObject.status = 'Provided value is NOT a number';
    return validationResultObjectObject;
  }

  if (options[MINVALUE] !== undefined && Number(value) < options[MINVALUE]) {
    validationResultObjectObject.status =
      'Provided value is less then ' + options[MINVALUE];
    return validationResultObjectObject;
  }

  if (isNaN(value)) {
    validationResultObjectObject.status =
      'Not a number has been provided for ' + options[USERFIELDNAME];
    return validationResultObjectObject;
  }

  if (!value) {
    validationResultObjectObject.status =
      'No value has been provided for ' + options[USERFIELDNAME];
    return validationResultObjectObject;
  }

  if (options[REMOVELEADINGZEROS] && options[REMOVELEADINGZEROS]) {
    validationResultObjectObject[VALUE] = Number(value);
  }

  if (options[MINLENGTH] && String(value).length < options[MINLENGTH]) {
    validationResultObjectObject.status =
      'Length of value is less then ' + options[MINLENGTH];
    return validationResultObjectObject;
  }

  if (options[MAXLENGTH] && String(value).length > options[MAXLENGTH]) {
    validationResultObjectObject.status =
      'Length of value is more then ' + options[MAXLENGTH];
    return validationResultObjectObject;
  }

  if (options[MAXVALUE] && Number(value) > options[MAXVALUE]) {
    validationResultObjectObject.status =
      'Provided value is greater then ' + options[MAXVALUE];
    return validationResultObjectObject;
  }

  validationResultObjectObject.status =
    'Provided value is correct for ' + options[USERFIELDNAME];
  validationResultObjectObject.result = true;

  return validationResultObjectObject;
};
