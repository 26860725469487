import React from 'react';
import './userList.css';
import User from '../User/User';

const UsersList = (props) => {
  return (
    <React.Fragment>
      {props.usersList.map((userElement) => {
        return <User userElement={userElement} key={userElement.uid}/>;
      })}
    </React.Fragment>
  );
};

export default UsersList;
