import React, { useEffect, useState, useRef } from "react";
import "./customerEdit.css";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getUserForUpdate } from "./../../../stores/BrainStore/BrainsStoreHelper";
import {
  setUserForEditStore,
  setForceUpdateStore,
} from "./../../../stores/BrainStore/BrainsAction";
import { getForceUpdate } from "./../../../stores/BrainStore/BrainsStoreHelper";
import {
  getErrorMessage,
  setErrorMessageForShow,
} from "./../../../stores/ErrorMessageStore/ErrorMessageStoreHelper";
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from "./../../../stores/SuccessMessageStore/SuccessMessageStoreHelper";
import { useNavigate } from "react-router";
import { CUSTOMERS, NEW } from "./../../../constants/constants.routes";
import { NEW_CUSTOMER_TITLE, SAVE_BUTTON } from "./../../../constants/constants.titles";
import { TEXT, NUMBER, TYPE } from "../../../constants/constants";
import {
  MAXLENGTH,
  MINLENGTH,
  REMOVELEADINGZEROS,
  USERFIELDNAME,
} from "../../../constants/constants.validationResult";
import { validateField } from "../../../service/Validator/validate.field";

const CustomerEdit = (props) => {
  const [isNew, setIsNew] = useState(false);
  const [customer, setCustomer] = useState({});
  const nameInputRef = useRef(null);
  const googleIDInputRef = useRef(null);

  let navigate = useNavigate();

  let { customerId } = useParams();

  const isNewFunction = () => {
    console.info("customerId:", customerId);
    return customerId === NEW ? true : false;
  };

  const saveButtonClick = async () => {
    console.info("saveButtonClick");
    console.info("customer:", customer);

    navigate(CUSTOMERS, { replace: true });
  };

  useEffect(() => {
    const newValue = isNewFunction();

    setIsNew(newValue);
    console.info("page CustomerEdit loaded");
    const currentCustomer = {
      name: "Zadarma Production 3",
      id: 12534,
      adWordsID: 12345678901,
    };

    console.info("newValue:", newValue ? "true" : "false");

    if (
      newValue === false
    ) {
      console.info("not new");
      setCustomer((customer) => ({ ...customer, ...currentCustomer }));
      nameInputRef.current.value = currentCustomer.name;
      googleIDInputRef.current.value = currentCustomer.adWordsID;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkField = (event, options) => {
    console.info("field:", event);

    validateField(event, options, props.setSuccessMessage, props.setErrorMessage);

    const updatedCustomer = Object.assign({});
    updatedCustomer[event.target.getAttribute("name")] = event.target.value;

    setCustomer((customer) => ({
      ...customer,
      ...updatedCustomer,
    }));
  };

  return (
    <div className="container-fluid text-center mt-5 w-25">
      <b>{isNew ? NEW_CUSTOMER_TITLE : "exisiting customer PUT CUSTOMER NAME HERE"}</b> <br />
      <div className="mt-2">
        <div>
          <div className="formElement">
            <span>Company name:</span>
            <input
              type="text"
              ref={nameInputRef}
              name="name"
              onChange={(event) => {
                checkField(event, {
                  [MINLENGTH]: 5,
                  [MAXLENGTH]: 10,
                  [USERFIELDNAME]: "Company name",
                  [TYPE]: TEXT,
                });
              }}
            />
          </div>
          <div className="formElement">
            AdWords Google ID#:
            <input
              ref={googleIDInputRef}
              name="adWordsID"
              onChange={(event) => {
                checkField(event, {
                  [MINLENGTH]: 3,
                  [MAXLENGTH]: 8,
                  [USERFIELDNAME]: "AdWords Google ID",
                  [REMOVELEADINGZEROS]: true,
                  [TYPE]: NUMBER,
                });
              }}
            />
          </div>
        </div>
      </div>
      {isNew === false ? <div className="mt-3">edit customer data section</div> : ""}
      <div className="mt-5">
        <button
          onClick={() => {
            saveButtonClick();
          }}
        >
          {SAVE_BUTTON}
        </button>
      </div>
      <div className="p-3">
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className="alert alert-danger" role="alert">
              {props.errorMessage}
            </div>
          ) : (
            ""
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className="alert alert-success" role="alert">
              {props.successMessage}
            </div>
          ) : (
            ""
          )
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserForEdit: (user) => {
      dispatch(setUserForEditStore(user));
    },
    setForceUpdate: (forceUpdate) => {
      dispatch(setForceUpdateStore(forceUpdate));
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    userForUpdate: getUserForUpdate(state),
    forceUpdate: getForceUpdate(state),
    errorMessage: getErrorMessage(state),
    successMessage: getSuccessMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
