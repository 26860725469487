import React, { useEffect, useState } from 'react';
import { getNewRemoteConfigRTDBObject } from '../../service/Models/RemoteConfigRCDB.model';
import {
  setRemoteConfigRCDB,
  getRemoteConfigRCDB,
} from '../../service/Firebase/remoteConfigRCDB.helper.firebase';
import {
  getErrorMessage,
  setErrorMessageForShow,
} from '../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from '../../stores/SuccessMessageStore/SuccessMessageStoreHelper';
import { connect } from 'react-redux';
import {
  OPTIONS,
  REMOTE_CONFIG_UPDATED_TIME,
} from '../../constants/constants.datamodel';
import CountDown from '../CountDown/CountDown';

const RemoteConfigDB = (props) => {
  const defaultRemoteConfigDBObject = getNewRemoteConfigRTDBObject();
  const [remoteConfigDBObject, setRemoteConfigDBObject] = useState(
    defaultRemoteConfigDBObject
  );

  const [blockerIsActive, setBlockerIsActive] = useState(false);

  useEffect(() => {
    async function fetchRemoteConfig() {
      const result = await getRemoteConfigRCDB();
      setRemoteConfigDBObject(result);
      setBlockerIsActive(checkIfBlockerIsActive(result));
    }

    fetchRemoteConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkBoxClick = (event, property) => {
    const tmpRemoteConfigDBObject = Object.assign({}, remoteConfigDBObject);

    // if (event.target.localName !== "input") {
    //   event.nativeEvent.preventDefault();
    // }

    tmpRemoteConfigDBObject[OPTIONS][property] =
      !remoteConfigDBObject[OPTIONS][property];
    event.nativeEvent.stopImmediatePropagation();

    setRemoteConfigDBObject(tmpRemoteConfigDBObject);
    setBlockerIsActive(checkIfBlockerIsActive(tmpRemoteConfigDBObject));
    saveButtonClick();
  };

  const saveButtonClick = async () => {
    const currentTime = new Date().getTime();
    remoteConfigDBObject[REMOTE_CONFIG_UPDATED_TIME] = currentTime;

    console.info('remoteConfigDBObject:', remoteConfigDBObject);

    await setRemoteConfigRCDB(remoteConfigDBObject)
      .then((result) => {
        props.setSuccessMessage(result);
        setRemoteConfigDBObject(remoteConfigDBObject);
      })
      .catch((error) => {
        props.setErrorMessage(error.message);
      });
  };

  const checkIfBlockerIsActive = (remoteConfigDBObject) => {
    const keys = Object.keys(remoteConfigDBObject[OPTIONS]);
    let result = false;

    keys.forEach((key) => {
      if (remoteConfigDBObject[OPTIONS][key] === true) {
        result = true;
        return;
      }
    });

    return result;
  };

  return (
    <div className='container-fluid text-center mt-5 w-25'>
      <div className='section-title'>Remote config</div>

      <div className='text-center fw-bold'>
        <CountDown
          startMoment={remoteConfigDBObject}
          blockerIsActive={blockerIsActive}
        />
      </div>

      {Object.keys(remoteConfigDBObject[OPTIONS]).map((key) => {
        return (
          <div
            className='left-text checkbox-permissions'
            key={key}
            onClick={(event) => checkBoxClick(event, key)}
          >
            <input
              type='checkbox'
              id={key}
              name={key}
              onChange={() => {}}
              checked={remoteConfigDBObject[OPTIONS][key]}
            />
            <label
              htmlFor={key}
              className='checkbox-permissions-label'
              onClick={(event) => {
                checkBoxClick(event, key);
              }}
            >
              {key} : {remoteConfigDBObject[OPTIONS][key] ? 'true' : 'false'}
            </label>
          </div>
        );
      })}

      <div className='p-3'>
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className='alert alert-danger' role='alert'>
              {props.errorMessage}
            </div>
          ) : (
            ''
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className='alert alert-success' role='alert'>
              {props.successMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getErrorMessage(state),
    successMessage: getSuccessMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteConfigDB);
