import { SET_NOTICE_MESSAGE, CLEAR_NOTICE_MESSAGE } from "./constantsNoticeMessageStore";

const initialState = {
  noticeMessage: "",
  component: "",
};

export const noticeMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTICE_MESSAGE:
      return { ...state, noticeMessage: action.payload };
    case CLEAR_NOTICE_MESSAGE:
      return { ...state, noticeMessage: action.payload };
    default:
      return state;
  }
};
