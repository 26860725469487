import { SET_SUCCESS_MESSAGE, CLEAR_SUCCESS_MESSAGE } from "./constantsSuccessMessageStore";

export function setSuccessMessage(successMessage) {
  return {
    type: SET_SUCCESS_MESSAGE,
    payload: successMessage,
  };
}

export function clearSuccessMessage() {
  return {
    type: CLEAR_SUCCESS_MESSAGE,
    payload: "",
  };
}
