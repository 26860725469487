import {
  AGATA_STOP_ACTIVITY,
  DELOS_STOP_ACTIVITY,
  REHOBOAM_STOP_ACTIVITY,
  REMOTE_CONFIG_UPDATED_TIME,
  OPTIONS,
} from '../../constants/constants.datamodel';

import { DELTA_REMOTE_CONFIG_MILLISECONDS } from '../../constants/constants';

export const RemoteConfigDBObject = {
  [OPTIONS]: {
    [DELOS_STOP_ACTIVITY]: false,
    [AGATA_STOP_ACTIVITY]: false,
    [REHOBOAM_STOP_ACTIVITY]: false,
  },
  [REMOTE_CONFIG_UPDATED_TIME]: 0,
};

export const getNewRemoteConfigRTDBObject = () => {
  return Object.assign({}, RemoteConfigDBObject);
};

export const isSystemUnderMaintenance = (currentRemoteConfig) => {
  console.info('before currentRemoteConfig:', currentRemoteConfig);

  for (const key of Object.keys(currentRemoteConfig[OPTIONS])) {
    if (!currentRemoteConfig[OPTIONS][key]) {
      delete currentRemoteConfig[OPTIONS][key];
    }
  }

  console.info('after currentRemoteConfig:', currentRemoteConfig);
  console.info(
    'after currentRemoteConfig.length:',
    Object.keys(currentRemoteConfig[OPTIONS]).length
  );

  console.info(
    'is maintenance period:',
    Object.keys(currentRemoteConfig[OPTIONS]).length > 0 ? true : false
  );

  return Object.keys(currentRemoteConfig[OPTIONS]).length > 0 ? true : false;
};

export const maintenanceTimeDelta = (currentRemoteConfig) => {
  const remoteConfigChangeTimeStamp =
    new Date().getTime() -
    (currentRemoteConfig[REMOTE_CONFIG_UPDATED_TIME] || new Date().getTime());
  console.info(
    'remoteConfigChangeTimeStamp currentRemoteConfig',
    currentRemoteConfig
  );
  console.info(
    'remoteConfigChangeTimeStamp currentRemoteConfig?.REMOTE_CONFIG_UPDATED_TIME:',
    currentRemoteConfig?.REMOTE_CONFIG_UPDATED_TIME
  );
  console.info('remoteConfigChangeTimeStamp:', remoteConfigChangeTimeStamp);

  if (remoteConfigChangeTimeStamp > DELTA_REMOTE_CONFIG_MILLISECONDS) {
    console.info('remoteConfigChangeTimeStamp greater');
  } else {
    console.info('remoteConfigChangeTimeStamp less');
  }

  return remoteConfigChangeTimeStamp;
};

export const maintenanceApplicationStatusIsBlocked = (currentRemoteConfig) => {
  if (
    isSystemUnderMaintenance(currentRemoteConfig) &&
    maintenanceTimeDelta(currentRemoteConfig) > DELTA_REMOTE_CONFIG_MILLISECONDS
  ) {
    return true;
  }

  return false;
};
