import React from 'react';
import { useNavigate } from 'react-router';
import { authenticateWithGoogle } from '../../service/Firebase/Firebase';
import { connect } from 'react-redux';
import {
  setCurrentUserStore,
  setCurrentUserPermissionsStore,
} from '../../stores/AuthStore/AuthAction';
import {
  setSpinnerStatusOn,
  setSpinnerStatusOff,
} from '../../stores/SpinnerStore/SpinnerAction';
import {
  getErrorMessage,
  setErrorMessageForShow,
} from './../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import { getCurrentUserValue } from './../../stores/AuthStore/AuthStoreHelper';
import { getUsersPermissions } from '../../service/Firebase/userPermissions.helper.firebase';
import { AUTOLOGIN, CURRENT_USER, NO } from './../../constants/constants';
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from './../../stores/SuccessMessageStore/SuccessMessageStoreHelper';
import {
  PERMISSIONS,
  STATUS,
  SUPERVISOR,
} from '../../constants/constants.datamodel';
import { AUTH_TIME } from '../../constants/constants';
import {
  getNoticeMessage,
  setNoticeMessageForShow,
} from '../../stores/NoticeMessageStore/NoticeMessageStoreHelper';
import { getRemoteConfigRCDB } from '../../service/Firebase/remoteConfigRCDB.helper.firebase';
import {
  isSystemUnderMaintenance,
  maintenanceApplicationStatusIsBlocked,
} from '../../service/Models/RemoteConfigRCDB.model';
import { ACCESS_LEVEL } from 'accesslevel';
import { setTimestampAuthTimeInSessionStorage } from '../../hooks/http.hook';
import { getUserPermissionsLevel } from 'accesslevel';

const SignIn = (props) => {
  let navigate = useNavigate();
  let currentRemoteConfig = {};
  let isUnderMaitenance = false;

  const signInFunction = async () => {
    props.setSpinnerOn();
    /* istanbul ignore next */
    await authenticateWithGoogle()
      .then((result) => {
        let user = null;
        if (result.hasOwnProperty('user')) {
          user = result.user;
        } else {
          throw new Error('No user property in Firebase Auth Object');
        }
        props.setCurrentUser(JSON.parse(JSON.stringify(user)));
        window.sessionStorage.setItem(CURRENT_USER, JSON.stringify(user));

        return user;
      })
      .then(async (user) => {
        currentRemoteConfig = await getRemoteConfigRCDB();
        isUnderMaitenance = isSystemUnderMaintenance(
          Object.assign({}, currentRemoteConfig)
        );

        return user;
      })
      .then((user) => {
        console.info('user:', user);
        let userId = null;
        let userEmail = null;
        if (user.hasOwnProperty('uid')) {
          userId = user.uid;
        } else {
          throw new Error('No id in user property of Firebase Auth Object');
        }
        if (user.hasOwnProperty('email')) {
          userEmail = user.email;
        } else {
          throw new Error('No email in user property of Firebase Auth Object');
        }

        return { userEmail, userId };
      })
      .then(async (result) => {
        const userPermisions = await getUsersPermissions(
          result.userEmail,
          result.userId
        );
        const userPermissionsLevel = getUserPermissionsLevel(
          userPermisions[PERMISSIONS]
        );

        if (isUnderMaitenance) {
          props.setNoticeMessage(
            'System is going to be under Maintenance. System will be blocked soon.'
          );
        }

        if (
          maintenanceApplicationStatusIsBlocked(
            Object.assign({}, currentRemoteConfig)
          )
        ) {
          props.setNoticeMessage(
            'System is under Maintenance. I guess you know what are you doing.'
          );

          if (userPermissionsLevel < ACCESS_LEVEL[SUPERVISOR]) {
            props.setNoticeMessage(
              'System is under Maintenance. Please try later.'
            );
            throw new Error('System is under Maintenance. Please try later.');
          }
        }

        setTimestampAuthTimeInSessionStorage();

        props.setSuccessMessage(userPermisions[STATUS]);

        props.setCurrentUserPermissions(userPermissionsLevel);
        window.sessionStorage.setItem(PERMISSIONS, userPermissionsLevel);

        const currentTime = new Date().getTime();
        window.sessionStorage.setItem(AUTH_TIME, currentTime);

        if (userPermissionsLevel === 0) {
          props.setErrorMessage('Current user has no any permissions.');
        }
        console.info('userPermisions:', userPermisions);
      })
      .then(() => {
        props.setSpinnerOff();
        navigate('/', { replace: true });
      })
      .catch((error) => {
        window.sessionStorage.setItem(AUTOLOGIN, NO);
        props.setCurrentUser(null);

        props.setSpinnerOff();
        console.info('error:', error);
        props.setErrorMessage(error.message);
      });
  };

  return (
    <div className='container-fluid text-center mt-5 w-50'>
      <div>
        <button
          id='loginWithGoogleButton'
          type='button'
          className='btn btn-primary btn-lg btn-block'
          onClick={signInFunction}
        >
          Sign In with Google
        </button>
      </div>
      <div className='p-3'>
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className='alert alert-danger' role='alert'>
              {props.errorMessage}
            </div>
          ) : (
            ''
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className='alert alert-success' role='alert'>
              {props.successMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (userData) => {
      dispatch(setCurrentUserStore(userData));
    },
    setCurrentUserPermissions: (userPermissionsLevel) => {
      dispatch(setCurrentUserPermissionsStore(userPermissionsLevel));
    },
    setSpinnerOn: () => {
      dispatch(setSpinnerStatusOn());
    },
    setSpinnerOff: () => {
      dispatch(setSpinnerStatusOff());
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
    setNoticeMessage: (errorMessage) => {
      setNoticeMessageForShow(dispatch, errorMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUserValue(state),
    errorMessage: getErrorMessage(state),
    successMessage: getSuccessMessage(state),
    noticeMessage: getNoticeMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
