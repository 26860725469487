import { SET_SUCCESS_MESSAGE, CLEAR_SUCCESS_MESSAGE } from "./constantsSuccessMessageStore";

const initialState = {
  successMessage: "",
  component: "",
};

export const successMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.payload };
    case CLEAR_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.payload };
    default:
      return state;
  }
};
