import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import * as CONSTANTS from "../../constants/constants.routes";
import SignIn from "../SignIn/SignIn";
import SignOut from "../SignOut/SignOut";
import NoPermissionGranted from "../NoPermissionsGranted/NoPermissionsGranted";
import Dashboard from "../Dashboard/Dashboard";
import { connect } from "react-redux";
import { getCurrentUserValue } from "../../stores/AuthStore/AuthStoreHelper";
import Customers from "../Customers/Customers";
import Users from "../Users/Users";
import CheckStatus from "../CheckStatus/CheckStatus";
import UserEdit from "../Users/UserEdit/UserEdit";
import CustomerEdit from "../Customers/CustomerEdit/CustomerEdit";
import CustomerAdvert from "../Customers/CustomerAdvert/CustomerAdvert";
import RemoteConfigDB from "../RemoteConfigRCDB/remoteConfigRCDB"

const RoutesArray = (props) => {
  return (
    <Router>
      <Navigation
        // permissionsGranted={props.permissionsGranted}
        currentUser={props.currentUser}
        // isAdmin={props.isAdmin}
        // initCompleted={props.initCompleted}
      />
      <hr />
      <Routes>
        <Route
          path={CONSTANTS.LANDING}
          element={props.currentUser ? <Dashboard /> : <NoPermissionGranted />}
        />
        <Route path={CONSTANTS.CUSTOMERS} element={<Customers />} exact />
        <Route path={CONSTANTS.USERS} element={<Users />} exact />
        <Route path={CONSTANTS.REMOTE_CONFIG} element={<RemoteConfigDB />} exact />
        <Route path="/status/:host" element={<CheckStatus />} exact />
        <Route path="/status/:host" element={<CheckStatus />} exact />

        <Route path={CONSTANTS.AUTH_SIGNIN} element={<SignIn />} exact />
        <Route path={CONSTANTS.AUTH_SIGNOUT} element={<SignOut />} exact />
        <Route path={`${CONSTANTS.AUTH_SIGNOUT}/:${CONSTANTS.FORCED}`} element={<SignOut />} exact />
        <Route path={`${CONSTANTS.USERS}/:userEmail`} element={<UserEdit />} exact />
        <Route
          path={`${CONSTANTS.CUSTOMERS}${CONSTANTS.EDIT}/:customerId`}
          element={<CustomerEdit />}
          exact
        />
        <Route
          path={`${CONSTANTS.CUSTOMERS}${CONSTANTS.ADVERT}/:customerId`}
          element={<CustomerAdvert />}
          exact
        />
      </Routes>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: getCurrentUserValue(state) };
};

export default connect(mapStateToProps, null)(RoutesArray);
