import { textValidator } from './text.validator';
import { numberValidator } from './number.validator';
import { TEXT, NUMBER, TYPE } from '../../constants/constants';
import {
  RESULT,
  REMOVELEADINGZEROS,
  VALUE,
} from '../../constants/constants.validationResult';
import { STATUS } from '../../constants/constants.datamodel';

export const validateField = async (
  fieldRef,
  options,
  setSuccessMessage,
  setErrorMessage,
  validationFieldResult
) => {
  let validationResult = true;

  switch (options[TYPE]) {
    case TEXT: {
      validationResult = textValidator(fieldRef.current.value, options);
      break;
    }
    case NUMBER: {
      validationResult = numberValidator(fieldRef.current.value, options);

      if (options[REMOVELEADINGZEROS] && options[REMOVELEADINGZEROS]) {
        fieldRef.current.value = validationResult[VALUE];
      }

      break;
    }
    default: {
      setErrorMessage('Unknown field type');
    }
  }

  if (validationResult[RESULT]) {
    fieldRef.current.classList.remove('inputWrongValue');
    fieldRef.current.classList.add('inputCorrectValue');
    setSuccessMessage(validationResult[STATUS]);
  } else {
    fieldRef.current.classList.remove('inputCorrectValue');
    fieldRef.current.classList.add('inputWrongValue');
    console.info('validation false');
    setErrorMessage(validationResult[STATUS]);
  }

  return validationResult[RESULT];
};

export const getRefCheckStatus = (ref) => {
  if (ref && ref?.current?.className?.includes('inputWrongValue')) {
    return false;
  } else if (ref && ref?.current?.className?.includes('inputCorrectValue')) {
    return true;
  }
  console.info('class missed');
  return undefined;
};
