/* istanbul ignore file */
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";

import "firebase/compat/database";
import "firebase/compat/storage";

// // Instrumented Test Environment
// InstrumentationRegistry.getContext()

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

let app = null;

if (getApps().length === 0) {
  app = initializeApp(config);
} else {
  app = getApp();
}

export const defaultUser = { loggedIn: false, email: "" };
export const firebaseInstance = app;

const auth = getAuth();
export const provider = new GoogleAuthProvider();

export const database = getDatabase(app);

export const authenticateWithGoogle = async () => {
  return signInWithPopup(auth, provider);
};
