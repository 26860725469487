import {
  ACCOUNT_MANAGER,
  SERVICE_MANAGER,
  SUPERVISOR,
  STATUS,
  USER_EMAIL,
  GRANTED,
  STARTTIME,
  ENDTIME,
  PERMISSIONS,
  UID,
  WEEK_DAYS,
  WEEK_END,
} from '../../constants/constants.datamodel';

export const UserPermissionObject = {
  [PERMISSIONS]: {
    [ACCOUNT_MANAGER]: false,
    [SERVICE_MANAGER]: false,
    [SUPERVISOR]: false,
  },
  [STATUS]: '',
  [USER_EMAIL]: '',
  [GRANTED]: false,
  [STARTTIME]: 0,
  [ENDTIME]: 23,
  [UID]: '',
  [WEEK_DAYS]: false,
  [WEEK_END]: false,
};

export const getNewUserPermissionsObject = () => {
  return Object.assign({}, UserPermissionObject);
};

export const isAccountManager = (objectIn) => {
  return objectIn[ACCOUNT_MANAGER];
};

export const isServiceManager = (objectIn) => {
  return objectIn[SERVICE_MANAGER];
};

export const isSuperviser = (objectIn) => {
  return objectIn[SUPERVISOR];
};

export const userPermissionsObjectGetStatus = (objectIn) => {
  return objectIn[STATUS];
};

export const userPermissionsObjectSetStatus = (objectIn, newStatus) => {
  objectIn[STATUS] = newStatus;
};

export const userPermissionsObjectGetUserEmail = (objectIn) => {
  return objectIn[USER_EMAIL];
};

export const userPermissionsObjectSetUserEmail = (objectIn, newUserEmail) => {
  objectIn[USER_EMAIL] = newUserEmail;
};
