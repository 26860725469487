import { database } from './Firebase';
import { ref, get } from 'firebase/database';
import { getNewUserPermissionsObject } from './../Models/UserPermissions.model';
import { getUserPermissionsLevelName } from 'accesslevel';
import { PERMISSIONS } from '../../constants/constants.datamodel';

export async function getUsers() {
  const snapshot = await get(ref(database, `/users/`))
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw new Error('issue with getting list of Users:' + error);
    });

  const snapshotData = await snapshot.val();

  console.info('snapshot data:', snapshotData);
  return snapshotData;
}

export async function getUser(uid) {
  const snapshot = await get(ref(database, `/users/${uid}`))
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw new Error('issue with getting User data:' + error);
    });

  const snapshotData = await snapshot.val();

  return Object.assign({}, getNewUserPermissionsObject(), snapshotData, {
    uid: String(uid),
  });
}

export function convertSnapshotToUserPremissionObject(key, snapshot) {
  const maxUserLevelName = getUserPermissionsLevelName(snapshot[PERMISSIONS]);
  const userPermissionsObject = Object.assign(
    {},
    getNewUserPermissionsObject(),
    snapshot,
    { uid: key },
    { maxUserLevelName: maxUserLevelName }
  );
  console.info('convert userPermissionsObject:', userPermissionsObject);
  return userPermissionsObject;
}
