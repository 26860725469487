import { SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE } from "./constantsErrorMessageStore";

export function setErrorMessage(errorMessage) {
  return {
    type: SET_ERROR_MESSAGE,
    payload: errorMessage,
  };
}

export function clearErrorMessage() {
  return {
    type: CLEAR_ERROR_MESSAGE,
    payload: "",
  };
}
