export const getCurrentUserValue = (state) => {
  return state?.userData?.user?.email ? state.userData.user.email : null;
};

export const getCurrentUserIDValue = (state) => {
  return state?.userData?.user?.uid ? state.userData.user.uid : null;
};

export const getAutoLoginTryUsedValue = (state) => {
  console.info(
    'getAutoLoginTryUsedValue:',
    state && state.userData && state.userData.autoLoginTryUsed !== null
      ? state.userData.autoLoginTryUsed
      : false
  );
  return state && state.userData && state.userData.autoLoginTryUsed !== null
    ? state.userData.autoLoginTryUsed
    : false;
};

export const getCurrentUserPermissionsLevel = (state) => {
  return state && state.userData && state.userData.permissions !== null
    ? state.userData.permissions
    : -1;
};
