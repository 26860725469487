import { setErrorMessage, clearErrorMessage } from "./ErrorMessageAction";

export const getErrorMessage = (state) => {
  return state.errorMessage ? state.errorMessage.errorMessage : "";
};

export const setErrorMessageForShow = (dispatch, errorMessage) => {
  dispatch(setErrorMessage(errorMessage));
  setTimeout(() => {
    dispatch(clearErrorMessage());
  }, 8000);
};
